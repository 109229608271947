@import (reference)'common/less/variables.less';


/* monospace font only numbers */
/* Noto Sans-regular - latin */
/*@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');    
    unicode-range: U+30-39;
    }*/


.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

// Unused?:
// .marginCollapseFix {
//     overflow: hidden;
// }

* {
    box-sizing: border-box;
}

.padding {
    padding: 5px;    
}

.doublePadding {
    padding: 10px;
}

html  {
    // Fix for bouncing sides on Apple tablets.
    // "overflow:hidden" need to be in the HTML block.
    overflow        : hidden !important;
}

html, body {

    .normalFont();
    color: @colors[graphiteGrey];
    text-shadow:1px 1px 1px 1px rgba(0,0,0,0.005);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    
    position        : fixed;
    @media print{
        position: inherit;
    }
    top             : 0;
    left            : 0;
    height          : 100%;
    width           : 100%;
    padding         : 0;
    margin          : 0;
}

body {
    z-index         : @zIndex[base];
    display         : flex;
    flex-direction  : column;
    -ms-scroll-chaining: none;
    -ms-content-zooming: none;

    // &.desktop {
    //     #textsizes > ._normalTextDesktop();
    // }
    // &.tablet {
    //     #textsizes > ._normalTextTablet();
    // }
    // &.mobile {
    //     #textsizes > ._normalTextMobile();
    // }
}

h1 {
    padding: 5px;

    // .desktop & {
    //     #textsizes > ._headerDesktop();
    // }
    // .tablet & {
    //     #textsizes > ._headerTablet();
    // }
    // .mobile & {
    //     #textsizes > ._headerMobile();
    // }
}

h2 {
    &:extend(h1);
    text-transform: none;
}

h3 {
    // .headerSmall() {
    //     .desktop & {
    //         #textsizes > ._headerSmallDesktop();
    //     }
    //     .tablet & {
    //         #textsizes > ._headerSmallTablet();
    //     }
    //     .mobile & {
    //         #textsizes > ._headerSmallMobile();
    //     }
    // }
}

h4 {
    &:extend(h3);
    text-transform  : none;
    line-height     : 1em;
    margin          : 0.5em 0;
}

hr {
    margin          : 5px 0;
    padding         : 0;
    border          : none;
    border-top      : 1px solid @borderColor;
}

html a {
    color           : mix( @blue, @normalTextColor, 75% );
    text-decoration : none;
}

a:hover {
    // color           : contrast( @hoverColor, @normalTextColor, @complimentaryTextColor );//@blue; //@colors[caspecoBlue];
    text-decoration : underline;
    text-decoration-color : @blue;
}

// for readability, set a max width for paragraphs
p {
    max-width       : 600px;
}

// #application {
//     display: flex;
//     flex-direction: column;
//     flex: auto;
//     background-color: white;
//     align-items: stretch;
// }
.center {
    text-align: center;
}

// Footer bar, holding issue tracker
.indexFooter {
    text-align: center;   
    background: linear-gradient(rgba(53, 53, 53, 0.1), rgba(53, 53, 53, 0.22));
    width:100%;
    padding:1px;
    & > span {
        & > a {
            color: #e8e8;
            letter-spacing: 0.3px;
        }
    }
    @media (max-width: 810px) {
        // Issue tracker does not work well on mobile devices
        display: none;
    }
}
.visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
  
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  
    white-space: nowrap;
}



//
// div, ul, table, tbody {
//   &:hover::-webkit-scrollbar-thumb:vertical {
//     background-color: rgba(0, 0, 0, .4);
//   }
// }
// ::-webkit-scrollbar {
// }
// ::-webkit-scrollbar:hover {
// }
// ::-webkit-scrollbar-thumb:vertical {
//     background-color: rgba(0, 0, 0, .0);
// }
// ::-webkit-scrollbar-button:start:decrement, ::-webkit-scrollbar-button:end:increment {
//     display: block;
// }
